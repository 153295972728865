var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "申请进度",
        center: "",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "120px",
                "label-suffix": ":",
              },
            },
            [
              _c("el-form-item", { attrs: { label: "申请单号", prop: "" } }, [
                _vm._v(" " + _vm._s(_vm.form.applyment_id) + " "),
              ]),
              _c("el-form-item", { attrs: { label: "申请状态", prop: "" } }, [
                _vm._v(" " + _vm._s(_vm.form.applyment_state_desc) + " "),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "驳回原因", prop: "" } },
                _vm._l(_vm.form.audit_detail, function (item, index) {
                  return _c("div", { key: index }, [
                    _c("div", [
                      _c("div", [
                        _vm._v("参数名称: " + _vm._s(item.param_name)),
                      ]),
                      _c("div", [
                        _vm._v("驳回原因: " + _vm._s(item.reject_reason)),
                      ]),
                    ]),
                  ])
                }),
                0
              ),
              _vm.form.account_name
                ? _c(
                    "el-form-item",
                    { attrs: { label: "账户验证", prop: "" } },
                    [
                      _c("div", [
                        _vm._v(
                          " 请使用" +
                            _vm._s(_vm.form.account_name) +
                            ":" +
                            _vm._s(_vm.付款卡号account_no) +
                            ",在" +
                            _vm._s(_vm.form.deadline) +
                            "之前,向" +
                            _vm._s(_vm.form.destination_account_name) +
                            "," +
                            _vm._s(_vm.form.city) +
                            "," +
                            _vm._s(_vm.form.destination_account_bank) +
                            ",卡号" +
                            _vm._s(_vm.form.destination_account_number) +
                            "进行一笔小额汇款。 "
                        ),
                      ]),
                      _c("div", [_vm._v("完成汇款后，请在此处填写汇款金额")]),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "120px",
                              "margin-right": "10px",
                            },
                            model: {
                              value: _vm.code,
                              callback: function ($$v) {
                                _vm.code = $$v
                              },
                              expression: "code",
                            },
                          }),
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v("验证"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "完成签约", prop: "" } },
                    [
                      _c("div", [
                        _vm._v(
                          "请经营者/法人使用微信扫描下方二维码，以完成签约验证"
                        ),
                      ]),
                      _vm.form.legal_validation_url_img
                        ? _c(
                            "div",
                            [
                              _c("el-image", {
                                attrs: {
                                  src: _vm.form.legal_validation_url_img,
                                  "preview-src-list": [
                                    _vm.form.legal_validation_url_img,
                                  ],
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.sign_url_img
                        ? _c(
                            "div",
                            [
                              _c("el-image", {
                                attrs: {
                                  src: _vm.form.sign_url_img,
                                  "preview-src-list": [_vm.form.sign_url_img],
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }