const bankList = [
  {
    id: '工商银行',
    name: '工商银行',
  },
  {
    id: '交通银行',
    name: '交通银行',
  },
  {
    id: '招商银行',
    name: '招商银行',
  },
  {
    id: '民生银行',
    name: '民生银行',
  },
  {
    id: '中信银行',
    name: '中信银行',
  },
  {
    id: '浦发银行',
    name: '浦发银行',
  },
  {
    id: '兴业银行',
    name: '兴业银行',
  },
  {
    id: '光大银行',
    name: '光大银行',
  },
  {
    id: '广发银行',
    name: '广发银行',
  },
  {
    id: '平安银行',
    name: '平安银行',
  },
  {
    id: '北京银行',
    name: '北京银行',
  },
  {
    id: '华夏银行',
    name: '华夏银行',
  },
  {
    id: '农业银行',
    name: '农业银行',
  },
  {
    id: '建设银行',
    name: '建设银行',
  },
  {
    id: '邮政储蓄银行',
    name: '邮政储蓄银行',
  },
  {
    id: '中国银行',
    name: '中国银行',
  },
  {
    id: '宁波银行',
    name: '宁波银行',
  },
  {
    id: '其他银行',
    name: '其他银行(例如:施秉县农村信用合作联社城关信用社)',
  },
]
export default bankList
