<template>
  <el-dialog
    title="申请进度"
    center
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        label-position="right"
        label-width="120px"
        label-suffix=":"
      >
        <el-form-item label="申请单号" prop="">
          {{ form.applyment_id }}
        </el-form-item>
        <el-form-item label="申请状态" prop="">
          {{ form.applyment_state_desc }}
        </el-form-item>
        <el-form-item label="驳回原因" prop="">
          <div v-for="(item, index) in form.audit_detail" :key="index">
            <div>
              <div>参数名称: {{ item.param_name }}</div>
              <div>驳回原因: {{ item.reject_reason }}</div>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-if="form.account_name" label="账户验证" prop="">
          <div>
            请使用{{ form.account_name }}:{{ 付款卡号account_no }},在{{
              form.deadline
            }}之前,向{{ form.destination_account_name }},{{ form.city }},{{
              form.destination_account_bank
            }},卡号{{ form.destination_account_number }}进行一笔小额汇款。
          </div>
          <div>完成汇款后，请在此处填写汇款金额</div>
          <div style="display: flex">
            <el-input
              v-model="code"
              style="width: 120px; margin-right: 10px"
            ></el-input>
            <el-button type="primary">验证</el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="0" label="完成签约" prop="">
          <div>请经营者/法人使用微信扫描下方二维码，以完成签约验证</div>
          <div v-if="form.legal_validation_url_img">
            <el-image
              :src="form.legal_validation_url_img"
              :preview-src-list="[form.legal_validation_url_img]"
            ></el-image>
          </div>
          <div v-if="form.sign_url_img">
            <el-image
              :src="form.sign_url_img"
              :preview-src-list="[form.sign_url_img]"
            ></el-image>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: '',
    components: {},
    data() {
      return {
        loading: false,
        showDialog: false,
        form: {},
      }
    },
    computed: {},
    watch: {
      showDialog(v) {
        if (v) {
          this.loading = true
          postAction('/shopAdmin/pay/apply-status').then((res) => {
            console.log('res', res)
            this.form = res.data
            this.loading = false
          })
        }
      },
    },
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
