var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "商户注册",
        visible: _vm.showDialog,
        width: "1100px",
        top: "4vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "div",
            {
              staticClass: "title",
              staticStyle: { display: "flex", "align-items": "center" },
            },
            [
              _c("div", { staticStyle: { "margin-right": "10px" } }, [
                _vm._v("1.请选择主体类型："),
              ]),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.organization_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "organization_type", $$v)
                    },
                    expression: "form.organization_type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 2500 } }, [
                    _vm._v("个人卖家"),
                  ]),
                  _c("el-radio", { attrs: { label: 4 } }, [
                    _vm._v("个体工商户"),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("企业")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("geren", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.form.organization_type == 2500,
                expression: "form.organization_type == 2500",
              },
            ],
            ref: "geren",
          }),
          _c("geti", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.form.organization_type == 4,
                expression: "form.organization_type == 4",
              },
            ],
            ref: "geti",
          }),
          _c("qiye", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.form.organization_type == 2,
                expression: "form.organization_type == 2",
              },
            ],
            ref: "qiye",
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }