<template>
  <el-dialog
    title="商户注册"
    :visible.sync="showDialog"
    width="1100px"
    top="4vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <div class="title" style="display: flex; align-items: center">
        <div style="margin-right: 10px">1.请选择主体类型：</div>
        <el-radio-group v-model="form.organization_type">
          <el-radio :label="2500">个人卖家</el-radio>
          <el-radio :label="4">个体工商户</el-radio>
          <el-radio :label="2">企业</el-radio>
        </el-radio-group>
      </div>
      <geren v-show="form.organization_type == 2500" ref="geren"></geren>
      <geti v-show="form.organization_type == 4" ref="geti"></geti>
      <qiye v-show="form.organization_type == 2" ref="qiye"></qiye>
    </div>
    <div class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">提交</el-button>
      <el-button @click="showDialog = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import geren from './a'
  import geti from './b'
  import qiye from './c'
  import bankList from '@/config/bank.js'

  export default {
    name: 'WeChatPayment',
    components: { geren, geti, qiye },
    data() {
      return {
        showDialog: false,
        form: {
          organization_type: 2500,
        },
      }
    },
    computed: {
      // 银行下拉
      bankSelect() {
        return bankList
      },
    },
    watch: {
      showDialog(v) {
        if (!v) {
          this.$refs.geren.clearForm()
          this.$refs.geti.clearForm()
          this.$refs.qiye.clearForm()
          this.$refs.qiye.form.bank_account_type = 74
          this.$refs.geren.form.bank_account_type = 75
        } else {
          postAction('/shopAdmin/pay/get-code').then((r) => {
            this.form.store_qr_code = r.data.code_url
          })
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      handleSubmit() {
        let endData = {}
        if (this.form.organization_type == 2500) {
          // 个人
          endData = this.$refs.geren.getData()
          console.log('endData', endData)
        } else if (this.form.organization_type == 4) {
          // 个体工商户
          endData = this.$refs.geti.getData()
          console.log('endData', endData)
        } else {
          // 企业
          endData = this.$refs.qiye.getData()
          console.log('endData', endData)
        }
        let b = this.bankSelect.filter((i) => i.name == endData.account_bank)
        console.log(b)
        if (b.length > 0) {
        } else {
          endData.bank_name = endData.account_bank
        }
        endData = {
          ...endData,
          ...this.form,
        }
        postAction('/shopAdmin/pay/apply', endData).then((r) => {
          console.log('r', r)
          this.$message.success('操作成功')
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .title {
    padding: 0 10px;
    margin-bottom: 10px;
    line-height: 30px;
    font-size: 16px;
    background-color: #eeeaea;
  }
  .updataFile {
    margin: 15px 0;
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  ::v-deep {
    .el-form-item--small.el-form-item {
      margin-bottom: 10px;
    }
  }
</style>
