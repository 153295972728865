<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-cascader
          v-model="form.area_id"
          :options="areaList"
          placeholder="选择区域"
          :props="{
            checkStrictly: true,
            value: 'id',
            label: 'name',
            emitPath: false,
          }"
          clearable
          style="width: 140px"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.channel_id"
          filterable
          clearable
          placeholder="请选择渠道"
        >
          <el-option
            v-for="item in channels"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          ref="clientSearch"
          :popper-class="'selectDC'"
          @select-id="clientSelect"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loadingT"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryMetod"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '单号'"
            class="underline"
            @click="handleOrderDetail(row)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <advanceOrder ref="advanceOrder"></advanceOrder>
    <diaobodan-order ref="diaobodanOrder" />
  </div>
</template>
<script>
  import ClientSearch from '@/baseComponents/clientSearch'
  import { getSelect } from '@/api/saleOrder'
  import { clientCustChannel } from '@/api/ClientDetail'
  import { postAction } from '@/api/Employee'
  import _ from 'lodash'
  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import diaobodanOrder from '@/views/project/treasury/requisition/documents/components/AddGoods.vue'

  export default {
    name: 'AccountQuery',
    components: { ClientSearch, advanceOrder, diaobodanOrder },
    data() {
      return {
        time: [],
        alltotal: 0,
        loadingT: false,
        list: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        form: {
          pageNo: 1,
          pageSize: 10,
        },
        areaList: [],
        channels: [],
        checkList: ['单号', '客户名称', '下单时间', '支付金额'],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'order_code',
            width: '',
          },
          {
            order: 2,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 3,
            label: '下单时间',
            prop: 'create_at',
            width: '',
          },
          {
            order: 4,
            label: '支付金额',
            prop: 'money',
            width: '',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.fetchData()
      // 客户下拉菜单数据获取
      getSelect().then((res) => {
        // this.selectRes = res
        let data = res.data
        this.areaList = data.cust_area //区域
      })
      this.clientCustChannelfun()
    },
    mounted() {},
    methods: {
      //查询-渠道
      clientCustChannelfun() {
        var that = this
        clientCustChannel(that.listdataAll).then((d) => {
          that.channels = d.data
        })
      },
      clientSelect(val) {
        if (val && val != -1) {
          this.form.cust_id = val
        } else {
          this.form.cust_id = ''
        }
      },
      async fetchData() {
        this.loadingT = true
        let res = await postAction('/shopAdmin/pay/wechat-list', this.form)
        this.list = res.data
        this.total = res.totalCount
        this.alltotal = res.ext
        this.loadingT = false
      },
      handleQuery() {
        this.pageNo = 1
        this.fetchData()
      },
      handleExport() {},
      summaryMetod(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [4]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '总计'
          }
        })
        console.log('sss', sums)
        sums[4] = this.alltotal
        return sums
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      handleOrderDetail(row) {
        console.log('row', row)
        if (row.order_code.indexOf('DB') == 0) {
          // db
          this.$refs.diaobodanOrder.dialogVisible = true
          this.$refs.diaobodanOrder.type = 1
          this.$refs.diaobodanOrder.handlerlist({ id: row.order_id })
        } else {
          this.$refs.advanceOrder.id = row.order_id
          this.$refs.advanceOrder.isshowDialog = true
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
