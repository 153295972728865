var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c("div", { staticClass: "title" }, [
        _vm._v(" 2.请填写经营者的身份证信息：2MB以内JPG/BMP/PNG彩色清晰照片 "),
      ]),
      _c("div", { staticClass: "updataFile" }, [
        _c(
          "div",
          [
            _c("upload-img", {
              ref: "uploadID",
              attrs: {
                "show-default": false,
                "show-img-list": "",
                "max-img": 1,
                "img-size": 2048000,
              },
            }),
            _c("h3", [_vm._v("上传身份证人像面照片")]),
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.deleteImg(1)
                  },
                },
              },
              [_vm._v("删除")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("upload-img", {
              ref: "uploadIDBack",
              attrs: {
                "show-default": false,
                "show-img-list": "",
                "max-img": 1,
                "img-size": 2048000,
              },
            }),
            _c("h3", [_vm._v("上传身份证国徽面照片")]),
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.deleteImg(2)
                  },
                },
              },
              [_vm._v("删除")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            "label-position": "right",
            "label-width": "190px",
            "label-suffix": ":",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "身份证姓名", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                model: {
                  value: _vm.form.id_card_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "id_card_name", $$v)
                  },
                  expression: "form.id_card_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证号码", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                model: {
                  value: _vm.form.id_card_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "id_card_number", $$v)
                  },
                  expression: "form.id_card_number",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证时间段", prop: "" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "450px" },
                attrs: {
                  type: "daterange",
                  "range-separator": "-",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("3.结算银行账户")]),
          _c(
            "el-form-item",
            { attrs: { label: "账户类型", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "450px" },
                  attrs: {
                    disabled: "",
                    clearable: "",
                    placeholder: "账户类型",
                  },
                  model: {
                    value: _vm.form.bank_account_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bank_account_type", $$v)
                    },
                    expression: "form.bank_account_type",
                  },
                },
                _vm._l(_vm.accountSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户银行", prop: "" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "450px" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    "allow-create": "",
                    "default-first-option": "",
                    placeholder: "开户银行",
                  },
                  model: {
                    value: _vm.form.account_bank,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "account_bank", $$v)
                    },
                    expression: "form.account_bank",
                  },
                },
                _vm._l(_vm.bankSelect, function (i, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { value: i.id, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户名称", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                attrs: { placeholder: "需与经营者姓名一致" },
                model: {
                  value: _vm.form.account_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "account_name", $$v)
                  },
                  expression: "form.account_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开户行所在地", prop: "" } },
            [
              _c("area-select", {
                staticStyle: { width: "450px" },
                on: { changeArea: _vm.changeArea },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "银行账号", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                model: {
                  value: _vm.form.account_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "account_number", $$v)
                  },
                  expression: "form.account_number",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("4.超级管理员信息")]),
          _c(
            "el-form-item",
            { attrs: { label: "超级管理员姓名", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                attrs: { placeholder: "需与经营者姓名一致" },
                model: {
                  value: _vm.form.contact_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contact_name", $$v)
                  },
                  expression: "form.contact_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "超级管理员姓名", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                model: {
                  value: _vm.form.contact_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contact_name", $$v)
                  },
                  expression: "form.contact_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "超级管理员身份证件号码", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                model: {
                  value: _vm.form.contact_id_card_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contact_id_card_number", $$v)
                  },
                  expression: "form.contact_id_card_number",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "超级管理员手机", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                model: {
                  value: _vm.form.mobile_phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mobile_phone", $$v)
                  },
                  expression: "form.mobile_phone",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("5.店铺信息")]),
          _c(
            "el-form-item",
            { attrs: { label: "商户简称", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.merchant_shortname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "merchant_shortname", $$v)
                  },
                  expression: "form.merchant_shortname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "店铺名称", prop: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "450px" },
                attrs: { placeholder: "例：华海商贸" },
                model: {
                  value: _vm.form.store_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "store_name", $$v)
                  },
                  expression: "form.store_name",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "补充说明", prop: "" } }, [
            _c("span", [
              _vm._v(
                " 该商户已持续从事电子商务经营活动满6个月，且期间经营收入 累计超过20万元。 "
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }