<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      inline
      label-position="right"
      label-width="160px"
      label-suffix=":"
    >
      <el-form-item label="在线支付开关" prop="">
        <el-switch
          v-model="form.pay_online"
          :active-value="'1'"
          :inactive-value="'0'"
          @change="handlePayChange"
        ></el-switch>
      </el-form-item>
      <div></div>
      <el-form-item label="默认支付方式" prop="">
        <el-select
          v-model="form.pay_type"
          clearable
          style="width: 170px"
          placeholder="支付方式"
          @change="handleTypeChange"
        >
          <el-option
            v-for="(i, idx) in payTypeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <div></div>

      <el-form-item label="当前微信支付商户号" prop="">
        <el-input
          v-model="form.sub_mchid"
          style="width: 170px"
          disabled
        ></el-input>
        <el-button
          style="margin-left: 10px"
          type="success"
          @click="handleWeChat"
        >
          申请开通微信支付商户号
        </el-button>
        <el-button type="success" @click="handleProgressQuery">
          申请进度查询
        </el-button>
      </el-form-item>
      <div></div>
      <el-form-item label="账户余额" prop="">
        <el-input
          v-model="form.balance"
          style="width: 170px"
          disabled
        ></el-input>
        <el-button
          style="margin-left: 10px"
          type="primary"
          @click="handleTixian"
        >
          申请提现
        </el-button>
      </el-form-item>
      <div></div>
      <el-form-item label="提现记录" prop="">
        <el-table v-loading="tableLoading" stripe :data="list">
          <!-- 序号 -->
          <el-table-column
            align="center"
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="money"
            label="余额"
            width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_at"
            label="提现时间"
            width="200"
            align="center"
          ></el-table-column>
        </el-table>
        <el-pagination
          :current-page="page.pageNo"
          :layout="layout"
          :page-size="page.pageSize"
          :total="total"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </el-form-item>
    </el-form>
    <we-chat-payment ref="weChatPayment"></we-chat-payment>
    <progress-query ref="progressQuery"></progress-query>
  </div>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import weChatPayment from './weChatPayment/index.vue'
  import progressQuery from './progressQuery/index.vue'
  export default {
    name: 'OnlinePayment',
    components: { weChatPayment, progressQuery },
    data() {
      return {
        form: {},
        loading: false,
        tableLoading: false,
        // 1货到付款3微信支付 1货到付款2账期结算3在线支付
        payTypeSelect: [
          //           1）默认在线支付，可选货到付款
          // 2）默认货到付款，可选在线支付
          // 3）仅可在线支付

          {
            id: '1',
            name: '默认货到付款，可选在线支付',
          },
          {
            id: '2',
            name: '默认在线支付，可选货到付款',
          },
          {
            id: '3',
            name: '仅可在线支付',
          },
        ],
        list: [],
        total: 0,
        layout: 'sizes, prev, pager, next, jumper',
        page: {
          pageSize: 10,
          pageNo: 1,
        },
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.fetchData()
      this.fetchList()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await postAction('/shopAdmin/pay/index')
        this.form = data
        this.loading = false
      },
      async fetchList() {
        this.tableLoading = true
        // 提现记录接口
        let { data } = await postAction('/shopAdmin/pay/withdraw-list')
        this.list = data
        this.tableLoading = false
      },
      handleCurrentChange(val) {
        this.page.pageNo = val
        this.fetchList()
      },
      handleSizeChange(val) {
        this.page.pageSize = val
        this.fetchList()
      },
      // 开通微信商户号
      handleWeChat() {
        this.$refs.weChatPayment.showDialog = true
      },
      // 进度查询
      handleProgressQuery() {
        this.$refs.progressQuery.showDialog = true
      },
      handleTixian() {
        postAction('/shopAdmin/pay/withdraw', {
          account_type: '', //账号类型，OPERATION 经营账号，BASIC基本账号，FEES手续费账号  默认BASIC
          amount: this.form.balance, //提现金额
          remark: '', //提现备注
          bank_memo: '', //银行附言
        }).then((r) => {
          this.$message.success('提现成功')
          this.fetchData()
          this.fetchList()
        })
      },
      // 对应的值,key=pay_online时(1开0关),key=pay_type时(1货到付款2账期结算3在线支付)
      handlePayChange(val) {
        console.log(val)
        postAction('/shopAdmin/pay/update-door', {
          key: 'pay_online',
          value: val,
        }).then((res) => {
          this.$message.success(res.msg)
        })
      },
      handleTypeChange(val) {
        console.log(val)
        postAction('/shopAdmin/pay/update-door', {
          key: 'pay_type',
          value: val,
        }).then((res) => {
          this.$message.success(res.msg)
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
