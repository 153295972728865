<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      label-position="right"
      label-width="190px"
      label-suffix=":"
    >
      <div class="title">2.营业执照/登记证书信息</div>
      <div>
        <div style="text-align: center; margin-bottom: 10px">
          <upload-img
            ref="uploadAimg"
            :show-default="false"
            show-img-list
            :max-img="1"
            :img-size="5848000"
          ></upload-img>
          <h3>上传营业执照证件扫描件</h3>
          <el-button @click="deleteImg(3)">删除</el-button>
        </div>
      </div>
      <el-form-item label="营业执照号" prop="">
        <el-input
          v-model="form.business_license_number"
          style="width: 450px"
        ></el-input>
      </el-form-item>
      <el-form-item label="公司名称" prop="">
        <el-input
          v-model="form.merchant_name"
          style="width: 450px"
          placeholder="需与营业执照一致"
        ></el-input>
      </el-form-item>
      <el-form-item label="经营者/法人姓名" prop="">
        <el-input
          v-model="form.legal_person"
          style="width: 450px"
          placeholder="需与营业执照一致"
        ></el-input>
      </el-form-item>
      <div class="title">
        3.请填写经营者的身份证信息：2MB以内JPG/BMP/PNG彩色清晰照片
      </div>
      <div class="updataFile">
        <div>
          <upload-img
            ref="uploadID"
            :show-default="false"
            show-img-list
            :max-img="1"
            :img-size="2048000"
          ></upload-img>
          <h3>上传身份证人像面照片</h3>
          <el-button @click="deleteImg(1)">删除</el-button>
        </div>
        <div>
          <upload-img
            ref="uploadIDBack"
            :show-default="false"
            show-img-list
            :max-img="1"
            :img-size="2048000"
          ></upload-img>
          <h3>上传身份证国徽面照片</h3>
          <el-button @click="deleteImg(2)">删除</el-button>
        </div>
      </div>

      <el-form-item label="身份证姓名" prop="">
        <el-input v-model="form.id_card_name" style="width: 450px"></el-input>
      </el-form-item>
      <el-form-item label="身份证号码" prop="">
        <el-input v-model="form.id_card_number" style="width: 450px"></el-input>
      </el-form-item>
      <el-form-item label="身份证时间段" prop="">
        <el-date-picker
          v-model="time"
          style="width: 450px"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>

      <!-- <el-form-item label="身份证有效期限" prop="">
        <el-date-picker
          v-model="form.id_card_valid_time"
          type="date"
          style="width: 450px"
          placeholder="选择日期"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item> -->
      <div class="title">4.结算银行账户</div>
      <el-form-item label="账户类型" prop="">
        <!-- <el-input v-model="accountType"></el-input> -->
        <el-select
          v-model="form.bank_account_type"
          clearable
          style="width: 450px"
          placeholder="账户类型"
        >
          <el-option
            v-for="(i, idx) in accountSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户银行" prop="">
        <el-select
          v-model="form.account_bank"
          clearable
          filterable
          allow-create
          default-first-option
          style="width: 450px"
          placeholder="开户银行"
        >
          <el-option
            v-for="(i, idx) in bankSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开户名称" prop="">
        <el-input
          v-model="form.account_name"
          style="width: 450px"
          placeholder="需与经营者姓名一致"
        ></el-input>
      </el-form-item>
      <el-form-item label="开户行所在地" prop="">
        <area-select
          style="width: 450px"
          @changeArea="changeArea"
        ></area-select>
      </el-form-item>
      <el-form-item label="银行账号" prop="">
        <el-input v-model="form.account_number" style="width: 450px"></el-input>
      </el-form-item>

      <div class="title">5.超级管理员信息</div>
      <el-form-item label="超级管理员类型" prop="">
        <el-select
          v-model="form.contact_type"
          clearable
          style="width: 450px"
          placeholder="超级管理员类型"
        >
          <el-option
            v-for="(i, idx) in adminSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="超级管理员姓名" prop="">
        <el-input v-model="form.contact_name" style="width: 450px"></el-input>
      </el-form-item>
      <el-form-item label="超级管理员身份证件号码" prop="">
        <el-input
          v-model="form.contact_id_card_number"
          style="width: 450px"
        ></el-input>
      </el-form-item>
      <el-form-item label="超级管理员手机" prop="">
        <el-input v-model="form.mobile_phone" style="width: 450px"></el-input>
      </el-form-item>
      <el-form-item label="超级管理员邮箱" prop="">
        <el-input v-model="form.contact_email" style="width: 450px"></el-input>
      </el-form-item>
      <div class="title">6.店铺信息</div>
      <el-form-item label="商户简称" prop="">
        <el-input
          v-model="form.merchant_shortname"
          style="width: 450px"
          placeholder=""
        ></el-input>
      </el-form-item>
      <el-form-item label="店铺名称" prop="">
        <el-input
          v-model="form.store_name"
          style="width: 450px"
          placeholder="例：华海商贸"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="补充说明" prop="">
        <span>
          该商户已持续从事电子商务经营活动满6个月，且期间经营收入
          累计超过20万元。
        </span>
      </el-form-item> -->
    </el-form>
  </div>
</template>
<script>
  import uploadImg from '@/baseComponents/uploadImg'
  import areaSelect from '@/baseComponents/areaSelect'
  import bankList from '@/config/bank.js'

  export default {
    components: { uploadImg, areaSelect },
    data() {
      return {
        showDialog: true,
        time: [],
        form: {},
        // 账户类型
        accountSelect: [
          {
            id: 75,
            name: '对私账户',
          },
          {
            id: 74,
            name: '对公账户',
          },
        ],
        // 超级管理员类型65=经营者/法人、66=负责人
        adminSelect: [
          {
            id: 65,
            name: '经营者/法人',
          },
          {
            id: 66,
            name: '负责人',
          },
        ],
      }
    },
    computed: {
      // 银行下拉
      bankSelect() {
        return bankList
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.id_card_valid_time_begin = val[0]
          this.form.id_card_valid_time = val[1]
        } else {
          this.form.id_card_valid_time_begin = ''
          this.form.id_card_valid_time = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      deleteImg(type = 3) {
        if (type == 1) {
          this.$refs.uploadID.fileList = []
        } else if (type == 2) {
          this.$refs.uploadIDBack.fileList = []
        } else if (type == 3) {
          this.$refs.uploadAimg.fileList = []
        } else {
          this.$refs.uploadID.fileList = []
          this.$refs.uploadIDBack.fileList = []
        }
      },
      clearForm() {
        this.deleteImg()
        this.form = {}
      },
      // 修改地区
      changeArea(v) {
        console.log(v)
        this.form.bank_address_code = v
      },
      getData() {
        // 获取身份证正反面图片url
        let id_card_copy =
          this.$refs.uploadID.fileList[0] && this.$refs.uploadID.fileList[0].url
        let id_card_national =
          this.$refs.uploadIDBack.fileList[0] &&
          this.$refs.uploadIDBack.fileList[0].url
        let business_license_copy =
          this.$refs.uploadAimg.fileList[0] &&
          this.$refs.uploadAimg.fileList[0].url
        let data = {
          ...this.form,
          id_card_copy,
          id_card_national,
          business_license_copy,
        }
        return data
      },
    },
  }
</script>
<style lang="scss" scoped>
  .title {
    padding: 0 10px;
    margin-bottom: 10px;
    line-height: 30px;
    font-size: 16px;
    background-color: #eeeaea;
  }
  .updataFile {
    margin: 15px 0;
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  ::v-deep {
    .el-form-item--small.el-form-item {
      margin-bottom: 10px;
    }
    .df {
      display: flex;
      justify-content: center;
    }
  }
</style>
