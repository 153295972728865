var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-radio-group",
        {
          staticStyle: { "margin-bottom": "15px" },
          model: {
            value: _vm.component,
            callback: function ($$v) {
              _vm.component = $$v
            },
            expression: "component",
          },
        },
        [
          _c("el-radio-button", { attrs: { label: "onlinePayment" } }, [
            _vm._v("在线支付管理"),
          ]),
          _c("el-radio-button", { attrs: { label: "accountQuery" } }, [
            _vm._v("账户流水查询"),
          ]),
        ],
        1
      ),
      _c(_vm.component, { tag: "component" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }