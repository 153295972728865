<template>
  <!-- NAME[epic=网销] 在线支付 -->
  <div class="orderTest-container">
    <el-radio-group v-model="component" style="margin-bottom: 15px">
      <el-radio-button label="onlinePayment">在线支付管理</el-radio-button>
      <el-radio-button label="accountQuery">账户流水查询</el-radio-button>
    </el-radio-group>
    <component :is="component"></component>
  </div>
</template>
<script>
  import onlinePayment from './components/onlinePayment'
  import accountQuery from './components/accountQuery'
  export default {
    name: 'PaymentSetting',
    components: { onlinePayment, accountQuery },
    data() {
      return {
        component: 'onlinePayment',
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
